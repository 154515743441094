import React from "react";


export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                {/* Use a div to wrap the logo */}
                <div className="logo">
                  <img
                    src={props.data ? props.data.img : "Loading"}
                    alt="Logo"
                  />
                </div>
                <span></span>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#about"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Get your free roadmap
                </a>{" "}
                {/* Fix extra whitespace */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
