import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>WHAT WE OFFER</h2>
          <p>
          Please review the services and packages we offer below. Revisions of work are agreed upon during onboarding, with limited changes allowed.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text5}</p>
                    <p>{d.text}</p>
                    <p>{d.text2}</p>
                    <p>{d.text3}</p>
                    <p>{d.text4}</p>
                    <h3>{d.price}</h3>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
