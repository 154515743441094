import React, { useEffect } from "react";

export const About = (props) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div id="about">
      <div className="container">
        <div className="row">
        
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              
              <p>We are a team of Software Engineers with experience in corporate, startup, and diverse environments, dedicated to helping grow both small and large businesses. Remember, our goal is to <strong> Save You Time and Money so we have happy clients.</strong></p>

              <p>You might be wondering why we offer these three services at such a low cost.</p>

              <p>Everyone knows that AI is crucial. With our service, we'll integrate AI to boost efficiency in your organization. During our consultation, we'll set up a small demonstration tailored to your current business.</p>

              <p>If you don't have a clear way of displaying your data, scaling to more customers becomes challenging. A well-organized dashboard is essential for making informed decisions.</p>

              <p>"Lastly, if you don't have a landing page to get clients, showcase your skills, and collect leads, it's only a shot in the dark.</p>
              {/*
                {/* Embed YouTube Video 
                <div className="video-responsive">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/dQw4w9WgXcQ" // Replace with your video URL
                    frameBorder="0"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded video"
                  ></iframe>
                </div>
              */}
            </div>
            
          </div>
         
          <div className="col-xs-12 col-md-6">
            <h4>Book Your Call Now To Get Your Free Roadmap </h4>
            {" "}
            {/* Calendly Widget */}
            <div
              className="calendly-inline-widget"
              data-url="https://calendly.com/hamza-farhatali/1on1"
              style={{ minWidth: "320px", height: "700px" }}
            ></div>          
          </div>
        </div>
        
      </div>
    </div>
  );
};
